<template>
<v-container fluid>
  <!-- page  title -->
  <div class="d-flex grey lighten-4 pa-3 ma-n3">
    <div class="text-h6 ml-5">邮件设置</div>
    <v-spacer></v-spacer>
  </div>
  <div class="pa-3">
    <div class="d-flex mt-8 align-center pa-2">
    <v-icon color="orange">mdi-lightbulb-on-outline</v-icon>
    <div class="text-subtitle-2 mx-3">管理员设置的是企业默认值，员工可以根据自己的偏好重新设置是否接收邮件</div>
  </div>
  <div style="border-left:3px solid green !important;" class="d-flex grey lighten-4 pa-3">
    <div class="text-subtitle-2 mr-4">消息邮件通知</div>
    <div class="text-caption grey--text">(当员工离线收不到消息时，允许系统发送邮件通知)</div>
  </div>
  <!-- communication notification -->
  <div>
    <div class="d-flex align-center">
      <div class="text-subtitle-2 mx-4">接收沟通类通知</div>
      <v-switch v-model="toggles" value="notification"></v-switch>
    </div>
    <div v-if="toggles.includes('notification')" class="px-3 mt-n7">
      <v-checkbox value="notification" v-model="settings">
        <template v-slot:label>
          <div class="text-subtitle-2 ">仅离线时接收邮件</div>
        </template>
      </v-checkbox>
    </div>
    <div class="text-subtitle-1 grey--text mx-4 mt-n3 mb-2">别人@我、@部门、@公告和回复我的消息（如果您的企业在使用APP，建议关闭）</div>
    <v-divider></v-divider>
  </div>
  <div>
    <div class="d-flex align-center">
      <div class="text-subtitle-2 mx-4">接收待办消息</div>
      <v-switch value="mail" v-model="toggles"></v-switch>
    </div>
    <div v-if="toggles.includes('mail')" class="px-3 mt-n7">
      <v-checkbox value="mail" v-model="settings">
        <template v-slot:label>
          <div class="text-subtitle-2">仅离线时接收邮件</div>
        </template>
      </v-checkbox>
    </div>
    <div class="text-subtitle-1 grey--text mx-4 mb-2 mt-n3">
      所有来自待办中心的消息提醒（此类邮件能让员工离线时不漏掉重要工作）
    </div>
    <v-divider></v-divider>
  </div>
  <div>
    <div class="d-flex align-center">
      <div class="text-subtitle-2 mx-4">接收工作通知</div>
      <v-switch value="jobNotification" v-model="toggles"></v-switch>
    </div>
    <div v-if="toggles.includes('jobNotification')" class="px-3 mt-n7">
      <v-checkbox value="jobNotification" v-model="settings">
        <template v-slot:label>
          <div class="text-subtitle-2">仅离线时接收邮件</div>
        </template>
      </v-checkbox>
    </div>
    <div class="text-subtitle-1 grey--text mx-4 mb-2 mt-n3">
      所有来自工作通知的消息，包含各类工作任务、项目等来自工作计划的通知
    </div>
    <v-divider></v-divider>
  </div>
  <div>
    <div class="mt-5">
      <div style="border-left:3px solid green !important;" class="d-flex grey lighten-4 pa-3">
        <div class="text-subtitle-2 mr-4">消息邮件通知</div>
        <div class="text-caption grey--text">(当员工离线收不到消息时，允许系统发送邮件通知)</div>
      </div>
    </div>
    <div class="d-flex align-center">
      <div class="text-subtitle-2 mx-4">每日日程邮件提醒</div>
      <v-switch value="dailyEmail" v-model="toggles"></v-switch>
    </div>
    <div class="px-3 mt-n7 d-flex">
      <v-checkbox class="mx-2" v-for="(day, i) in daysOfWeek" :key="i" :value="day.value" v-model="settings">
        <template v-slot:label>
          <div class="text-subtitle-2">{{ day.text }}</div>
        </template>
      </v-checkbox>
    </div>
    <div class="text-subtitle-1 grey--text mx-4 mb-2 mt-n3">
      所设置的日期上午八点接收需要处理的工作任务提醒邮件
    </div>
    <v-divider></v-divider>
  </div>
  </div>
  
</v-container>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'EmailPage',
  data: () => ({
    toggles: [],
    settings: [],
    daysOfWeek: [
      {
        text: '每周一',
        value: 'mon'
      },
      {
        text: '每周二',
        value: 'tue'
      },
      {
        text: '每周三',
        value: 'wed'
      },
      {
        text: '每周四',
        value: 'thu'
      },
      {
        text: '每周五',
        value: 'fri'
      },
      {
        text: '每周六',
        value: 'sat'
      },
      {
        text: '每周日',
        value: 'sun'
      }
    ]
  }),
  watch: {
    toggles: function () {
      this.setSettingCall({
        st_category_id: 1,
        st_category_type: 111,
        st_value: this.toggles.join()
      })
    },
    settings: function () {
      this.setSettingCall({
        st_category_id: 2,
        st_category_type: 111,
        st_value: this.settings.join()
      })
    }
  },
  methods: {
    ...mapActions('app', ['getSettingCall', 'setSettingCall']),
  },
  mounted() {
    this.getSettingCall({
      st_category_id: 1,
      st_category_type: 111
    }).then(res => {
      this.toggles = res.split(',');
    });
    this.getSettingCall({
      st_category_id: 2,
      st_category_type: 111
    }).then(res => {
      this.settings = res.split(',');
    });
  }
}
</script>
